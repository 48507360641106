import moment from 'moment'
import HeaderTopDashboard from '../../../../components/Layout/HeaderTopDashboard.vue'
import EventBus from '../../../../util/EventBus.js'
import CampaignDltValidationService from '../../../../services/campaign-dlt-validation.service'
import DltInfoDialog from '../DLT/DltInfoDialog/DltInfoDialog.vue'

const COUNTRY_INDIA_ID = 73

export default {
  name: 'DltSms',
  components: {
    HeaderTopDashboard,
    DltInfoDialog,
  },
  data: function () {
    return {
      show: false,
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      content: [],
      pageCount: 0,
      total: 0,
      createDialog: false,
      loadingCreateDialog: false,
      editDialog: false,
      loadingEditDialog: false,
      deleteDialog: false,
      loadingDeleteDialog: false,
      dltId: 0,
      enterpriseId: '',
      sender: '',
      templateId: '',
      templateContent: '',
      showDltInfo: false,
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$t('ID de empresa'), value: 'enterprise_id' },
        { text: this.$t('Remitente'), value: 'sender' },
        { text: this.$t('ID de plantilla'), value: 'template_id' },
        { text: this.$t('Contenido de plantilla'), value: 'template_content' },
        { text: this.$t('Fecha'), value: 'created_at' },
        { text: this.$t('Estatus'), value: 'status' },
        { text: this.$t('Info'), value: 'comment', width: '20px', sortable: false },
        { text: this.$t('Acciones'), value: 'actions' },
      ]
    },
    itemStatus () {
      return [
        { value: 'pendiente', text: this.$t('Pendiente') },
        { value: 'aprobado', text: this.$t('Aprobado') },
        { value: 'rechazado', text: this.$t('Rechazado') },
      ]
    },
    params (nv) {
      return {
        ...this.options,
        query: [],
      }
    },
  },
  watch: {
    params: {
      handler () {
        this.setParamsAndGetData()
      },
      deep: true,
    },
  },
  methods: {
    getStatus (item) {
      return this.itemStatus.find(s => s.value === item.status).text
    },
    toggleCreateDialog () {
      const query = Object.assign({}, this.$route.query)
      if (query.new) {
        delete query.new
        this.$router.replace({ query })
      }
      this.createDialog = !this.createDialog
    },
    getDataFromApi (params = {}) {
      EventBus.$emit('showLoading', true)
      this.content = []
      this.loading = true
      CampaignDltValidationService.getAll(params)
        .then(
          (response) => {
            this.content = JSON.parse(JSON.stringify(response.data))
            this.itemsPerPage = parseInt(response.per_page)
            this.page = response.current_page
            this.total = response.total
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
        })
    },
    setParamsAndGetData () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
        ? 'asc'
        : 'desc'
      }

      this.getDataFromApi(params)
    },
    createDlt () {
      this.loadingDialog = true

      const data = {
        country_id: COUNTRY_INDIA_ID,
        enterprise_id: this.enterpriseId,
        sender: this.sender,
        template_id: this.templateId,
        template_content: this.templateContent,
      }

      CampaignDltValidationService.create(data).then(
        () => {
          this.setParamsAndGetData()
          EventBus.$emit('showAlert', 'success', this.$t('DLT creado correctamente'))
          this.toggleCreateDialog()
          this.resetDlt()
        },
        (error) => {
          console.log(error)
        },
      ).finally(() => {
        this.loadingDialog = false
      })
    },
    updateDlt () {
      this.loadingEditDialog = true

      const data = {
        id: this.dltId,
        country_id: COUNTRY_INDIA_ID,
        enterprise_id: this.enterpriseId,
        sender: this.sender,
        template_id: this.templateId,
        template_content: this.templateContent,
      }

      CampaignDltValidationService.update(data.id, data).then(
        () => {
          this.setParamsAndGetData()
          EventBus.$emit('showAlert', 'success', this.$t('DLT actualizado correctamente'))
          this.editDialog = false
          this.resetDlt()
        },
        (error) => {
          console.log(error)
        },
      ).finally(() => {
        this.loadingEditDialog = false
      })
    },
    openEditDlt (item) {
      this.dltId = item.id
      this.enterpriseId = item.enterprise_id
      this.sender = item.sender
      this.templateId = item.template_id
      this.templateContent = item.template_content
      this.editDialog = true
    },
    closeEditDlt () {
      this.resetDlt()
      this.editDialog = false
    },
    openDeleteDlt (item) {
      this.dltId = item.id
      this.deleteDialog = true
    },
    closeDeleteDlt () {
      this.dltId = null
      this.deleteDialog = false
    },
    deleteDlt () {
      this.loadingDeleteDialog = true

      const data = {
        id: this.dltId,
      }

      CampaignDltValidationService.destroy(data.id).then(
        () => {
          this.setParamsAndGetData()
          EventBus.$emit('showAlert', 'success', this.$t('DLT eliminado correctamente'))
          this.deleteDialog = false
          this.resetDlt()
        },
        (error) => {
          console.log(error)
        },
      ).finally(() => {
        this.loadingDeleteDialog = false
      })
    },
    resetDlt () {
      this.$refs.observer.reset()
      this.dltId = 0
      this.enterpriseId = ''
      this.sender = ''
      this.templateId = ''
      this.templateContent = ''
    },
    date (date) {
      return moment(date).format('DD-MM-YYYY HH:mm:ss')
    },
  },
  mounted () {
    if (this.$route.query.new) {
      this.createDialog = true
    }
  },
}
