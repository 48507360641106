export default {
  name: 'DltInfoDialog',
  props: {
    showDltInfo: {
      type: Boolean,
      required: true,
    },
  },
  components: {
  },
  data: function () {
    return {
      urls: [
        'https://dltconnect.airtel.in',
        'https://trueconnect.jio.com',
        'https://www.ucc-bsnl.co.in',
        'https://www.tping.livvilpower.in',
        'https://smare/entity/home',
      ],
    }
  },
  methods: {
    closeDlg () {
      this.$emit('close')
    },
  },
}
